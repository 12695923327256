@font-face {
	font-family: 'latos';
	src: url('./fonts/lato-regular.woff2') format('woff2')
}

.landing-section {
	height: 100vh;
}

.landing-page-parent{
	position: relative;
	width: 100vw;
	min-height: 90vh;
	height: fit-content;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	background-color: rgb(255, 255, 255);
	align-items: center;
	/* overflow-x: hidden; */
}

.name-place{
	position: relative;
	background-color: rgb(90, 82, 97);
	height: 50vh;
	width: 100vw;
}

.animation-place{
	position: relative;
	background-color: rgb(0, 0, 0);
	height: 50vh;
	width: 100vw;
}

.gif-container {
	position: relative;
	width: 80vw;
	height: 60vw;
	z-index: 2;
}

.spline-container {
	position: relative;
	width: fit-content;
	max-width: 90vw;
	height: fit-content;
	align-items: center;
	margin-left: 20vh;
	z-index: 2;
	transform: translateY(150px);
}

.content {
	max-width: 80vw;
	position: relative;
	/* animation: rise_up 3s ease-in-out forwards; */
	z-index: 5;
	transform: translateY(-5vh);
}

@keyframes rise_up {
	0% {
		transform: translateY(20vh);
		opacity: 0;
	}
	100% {
		transform: translateY(-5vh);
		opacity: 1;
	}
}

@media (max-height: 800px) {
	.spline-container {
		transform: translateY(150px) scale(0.6);
	}
	.landing-page-parent{
		max-height: 110vh;
	}
}

@media (max-width: 768px) {
	.spline-container {
		position: absolute;
		align-items: center;
		margin-left: 0%;
		left: 0px;
		transform: translateY(275px) scale(0.3);
		max-width: 10vw;
		padding-bottom: 0%;
	}
	.landing-page-parent{
		min-height: 65vh;
	}

	.content {
		transform: translateX(5vw) translateY(5vh);
	}

	@keyframes rise_up {
		0% {
			transform: translateY(5vh);
			opacity: 0;
		}
		100% {
			transform: translateY(0vh);
			opacity: 1;
		}
	}
}

.company-title{
	position: absolute;
	display: flex;
	/* width: fit-content; */
	width: 100vw;
	max-width: fit-content;
	height: fit-content;
	left: 22vw;
	top: 5%;
}

.company-5g {
	/* background: -webkit-linear-gradient(rgb(0,0,0), rgb(243, 0, 0)); */
	background-color: black;
	-webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
	
	text-align: center;
	/* color: #c00303; */
	font-size: 600%;
	font-weight: 900;
	width: 100vw;
	/* position: absolute; */
	/* z-index: 5; */
	margin-top: 60vh;
	/* margin-left: 15vw; */
}

.content h2 {
	color: #be0606;
	font-size: 12em;
	position: absolute;
  letter-spacing: 30px;
	font-family:'Verdana';
}

.content h2:nth-child(1)::after {
	content: "5G";
	font-size: 3rem;
	font-weight: 600;
	letter-spacing: 1px;
	color: blue;
	-webkit-text-stroke: 0px;
	position: absolute;
	right: -5vw;
	bottom: -2vh;
}

.content h2:nth-child(1) {
	color: transparent;
	-webkit-text-stroke: 2px #6e6e6e;
	text-shadow: 2px 2px rgba(243, 243, 243, 0.2);

	/* color: 	#fcf6e7; */
	/* -webkit-text-stroke: 2px #a00000; */
}

.content h2:nth-child(2) {
	/* color:  #be0606; */
	background: -webkit-linear-gradient(#fd6568, #AD0507);
	-webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
	animation: animate 4s ease-in-out infinite;
}

@keyframes animate {
	0%,
	100% {
		clip-path: polygon(
			0% 45%,
			16% 44%,
			33% 50%,
			54% 60%,
			70% 61%,
			84% 59%,
			100% 52%,
			100% 100%,
			0% 100%
		);
	}

	50% {
		clip-path: polygon(
			0% 60%,
			15% 65%,
			34% 66%,
			51% 62%,
			67% 50%,
			84% 45%,
			100% 46%,
			100% 100%,
			0% 100%
		);
	}
}

@media (max-height: 800px) {
	.content h2 {
		font-size: 10em;
	}
	.company-title {
		left: 22vw;
		height: 60vh;
	}
}

@media (max-width: 768px) {
  /* Font sizes for tablets and larger screens */
  .content h2 {
		color: #be0606;
		font-size: 6em;
		position: absolute;
		letter-spacing: 6px;
	}
	.content h2:nth-child(1) {
		color: transparent;
		-webkit-text-stroke: 1px #6e6e6e;
	}
	.content h2:nth-child(1)::after {
		font-size: 2rem;
	}
	.company-title{
		left: 10vw;
		min-width: 80vw;
		height: 15vh;
	}
	.company-5g {
		font-size: 400%;
		font-weight: 900;
		position: relative;
		text-align: center;
		top: 10vh;
	}
}
.pricing-page-section{
	position: relative;
	width: 100vw;
	height: 100vh;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background-color: #ffffff;
	background-image: url(../css/images/img1.jpg);
	background-size: cover;
	background-position: center center;
	}

.box {
	width: 80vw;
	height: 85vh;
	display: flex;
	flex-direction: column;
	}

.my-row {
    flex: 1;
    border: 1px solid  rgb(25, 25, 25);
	background-color: rgba(32, 32, 32, 0.9);
    text-align: center;
	border-radius: 10px;
	display: flex;
    flex-direction: row;
	z-index: 1;
	
}

.my-row:hover{
	border: solid 1px rgba(255, 255, 255, 0.1);
	background-color: rgba(67, 67, 67, 0.9);
}

.cols {
	flex: 1;
	text-align: center;
	align-self: center;
	font-size: 110%;
	color: inherit;
	font-family: 'latos';
	z-index: 2;
	color: #ffffff;
}

.header-logo {
	max-height: 100px;
	padding: 15px;
	margin-left: 20px;
}

.header-btns {
	padding-top: 3vh;
}

.header-buttons{
	width: 100vw;
	position: relative;
	top: 0;
	left: 0;
	background-color:#ffffff;
	display: flex;
	flex-direction: row;
	margin-top: vh;
}

.enquire-button{
	font-size: 90%;
	padding: 2px;
	min-height: 5vh;
	height: fit-content;
	width: 15vh;
	/* background-color:#be0606; */
	background-color: #BB1008;
	border: solid 1.5px #be0606;
	color: white;
	border-radius: 25px;
}	

.pricing-button{
	font-size: 90%;
	padding: 2px;
	min-height: 5vh;
	height: fit-content;
	width: 15vh;
	margin-left: 15px;
	color: inherit;
	background-color: inherit;
	border: solid 1.5px #292929;
	border-radius: 25px;
	color: #000000;
}

.login-button{
	margin-left: 5px;
	margin-top: 0%;
	max-height: 5vh;
	background-color: inherit;
	margin: 3px;
}

.login-comp {
	display: inline-block;
	width: fit-content;
}

.login-comp .tooltiptext {  
	visibility: hidden;  
	width: fit-content;
	border: solid 1px rgb(177, 177, 177);;
	color: rgb(0, 0, 0);
	font-size: 80%;  
	text-align: center;
	padding: 3px;
	position: absolute;
	border-radius: 3px;
	z-index: 1;  
}  
.login-comp:hover .tooltiptext {  
	visibility: visible;  
}  

@media (max-width: 768px) {
	.header-btns{
		padding-top: 1vh;
		max-height: 80px;
		padding: 10px;
	}

	.header-logo {
		max-height: 80px;
		padding: 10px;
		margin-left: 5px;
	}

	.enquire-button{
		height: 5vh;
		width: 15vh;
		/* background-color:#be0606; */
		background-color: #BB1008;
		border: none;
		color: white;
		border-radius: 25px;
		font-size: 100%;
	}	
	
	.pricing-button{
		height: 5vh;
		width: 15vh;
		color: inherit;
		background-color: inherit;
		border: solid 1.5px #292929;
		border-radius: 25px;
		font-size: 100%;
		color: #000000;
	}

	.login-button{
		position: absolute;
		/* top: 5vh; */
		right: -2vh;
		padding: 1px;
		padding-top: 3px;
		transform: scale(0.8);
		max-height: fit-content;
	}
}
.header-row{
	flex: 1;
	border: 1px solid  rgb(25, 25, 25);
	text-align: center;
	border-radius: 10px;
	display: flex;
	flex-direction: row;
	background-color: rgb(190, 25, 25);
}

.header-col .cols{
	color: #ffffff;
	text-transform: capitalize;
	font-size: 110%;
	font-family: 'latos';
	font-weight: 500;
}


.netspeed{
	color: rgb(255, 2, 2);
}

.additional-charge {
	font-style: italic;
	color: white;
	font-size: 75%;
	width: 80vw;
	text-align: end;
}

.about-us-section{
	min-height: 100vh;
	height: fit-content;
	width: 100vw;
	background-color: #ffffff;
	display: flex;
	justify-content: center;
	align-items: center;
}

.about-text{
	font-size: 250%;
	padding: 10vw;
	font-weight: 200;
}

.contact-form{
	left: 0;
	top: 0;
	display: flex;
	width: 100vw;
	min-height: 100vh;
	padding-bottom: 25px;
	flex-direction: row;
	justify-content: center;
	/* background: linear-gradient(25deg,#ffffff 0%, rgb(255, 255, 255) 128%); */
	background-color: white;
	background-size: cover;
	background-position: center center;
	/* background-color: white; */
}

.inputBx {
	position: relative;
	width: 100%;
	margin: 0px;
	margin-bottom: 20px;
	opacity: 1;
}

.form-fields{
	position: relative;
	font-size: 90%;
	font-weight: 400;
	outline: none;
	/* border: 1px solid rgba(255, 255, 255, 0.2); */
	border: none;
	/* border-bottom: 1px solid rgb(167, 4, 4); */
	border-bottom: 0.5px solid rgb(184, 184, 184);
	background: inherit;
	padding: 5px;
	padding-left: 40px;
	/* border-radius: 15px; */
	color: rgb(255, 255, 255);
	/* box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1); */
	opacity: 1;
}


.form-span {
	font-size: 110%;
	font-weight: 450;
	position: relative;
	padding: 10px;
	left: 30px;
	display: inline-block;
	color: rgb(255, 255, 255);
	text-transform: capitalize;
	text-align: left;
	display: inline-block;
	opacity: 1;
}

.form-section {
	margin-top: 15vh;
	justify-content: center;
}

.form-title {
	text-align: center;
	font-size: 200%;
	color: rgb(65, 65, 65);
	font-weight: 600;
	align-self: center;
	padding-top: 5vh;
	/* font-family: "Comic Sans MS"; */
	font-family: "Arial";
}

.form-area {
	width: 50vw;
	height: fit-content;
	min-height: 70vh;
	margin-top: 10vh;
	border-radius: 10px;
	box-shadow:  4px 4px 8px rgba(0, 0, 0, 0.3);
	padding-top: 5vh;
	padding-bottom: 5vh;
	background-color: rgb(190, 25, 25);
	background-size: cover;
	background-position: center center;
	opacity: 1;
	font-family: "Arial";
	/* font-family: "Comic Sans MS"; */
}

.address-field {
	min-height: 15vh;
}

.comment-field {
	min-height: 10vh;
}

.map-section {
	border: solid 1px black;
	margin: 5vh;
}

.btn-enquire {
	color: rgb(255, 255, 255);
	border: 1px solid rgb(92, 0, 0);
	/* background: rgb(92, 0, 0); */
	background: rgb(0, 0, 0);
	padding: 10px 25px;
	margin-top: 15px;
	border-radius: 5px;
	opacity: 1;
}

.btn-locate-me {
	position: absolute;
	bottom: 10px;
	right: 10px;
	color: rgb(92, 0, 0);
	/* border: 1px solid rgb(92, 0, 0); */
	background: inherit;
	padding: 5px 5px;
	border-radius: 5px;
	height: fit-content;
	opacity: 1;
	margin-top: 100px;
	font-size: 70%;
	cursor: pointer;
	animation: popBtn 5s ease-in-out infinite;
}

@keyframes popBtn {
	0%{
		transform: translateY(0);
	}
	15% {
		transform: translateY(-20px);
	}
	35% {
		transform: translateY(0);
	}
	40% {
		transform: translateY(0);
	}
	55%{
		transform: translateY(-20px);
	}
	70% {
		transform: translateY(0);
	}
	100% {
		transform: translateY(0);
	}
}

.location-fetch-toast {
	/* visibility: hidden;   */
	position: fixed;
	top: 5vh;
	width: fit-content;
	padding: 5px 20px;
	text-align: center;
	border: solid 1px black;
	border-radius: 5px;
	/* left: 45vw; */
}

.footer {
	position: absolute;
	left: 0%;
	transform: translateX(15px);
	width: 120%;
	margin: 0%;
	height: fit-content;
	min-height: 30vh;
	padding: 20px;
	color: #000000;
	background: #fcf6e7;
	/* font-family: "Comic Sans MS"; */
	font-family: 'Arial';
}

.footer-company {
	text-align: right;
	display: flex;
}

.footer-logo {
	max-width: 250px;
	max-height: 200px;
}

.footer-description {
	font-size: 90%;
	text-align: left;
	padding: 50px 30px;
	width: 25vw;
}

.footer-address {
	text-align: left;
	font-size: 100%;
	padding: 30px 0 10px 30px;
	justify-content: center;
}

.footer-contact {
	text-align: left;
	font-size: 100%;
	padding: 30px 0 30px 30px;
}

.footer-dev {
	font-size: 80%;
	background: #000000;
	color: #ffffff;
	position: absolute;
	bottom: 0%;
	width: 100%;
	padding: 10px;
	padding-bottom: 10px;
	/* font-family: 'Arial'; */
	font-family: "Comic Sans MS";
}

.codespice {
	text-decoration: none;
	color:rgb(132, 233, 0);
	font-weight: 600;
	cursor: pointer;
}

@media (max-width: 768px) {
	.footer {
		transform: translateX(10px);
	}
}

.privacy-link {
	display: block;
	font-size: 80%;
	text-decoration: none;
	color: black;
}

.privacy-page {
	width: 100vw;
	padding: 10vh 10vw;
	text-align: justify;
}

.privacy-header {
	margin: 10vh 0 5vh 0;
}

.privacy-para {
	font-size: 15px;
}

.privacy-subheading {
	color:rgb(190, 25, 25);
	font-weight: bold;
}